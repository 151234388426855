import React,
{
  useRef,
  useState,
}
  from "react";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Button,
  Editing,
  Scrolling,
  Toolbar,
  Grouping,
  GroupPanel
} from "devextreme-react/data-grid";
import TabPanel, {
  Item
} from "devextreme-react/tab-panel";
import Form, {
  Label,
  SimpleItem,
  RequiredRule,
} from "devextreme-react/form";
import {
  Popup,
  ToolbarItem
} from 'devextreme-react/popup';
import { calculateGridHeight } from '../../utils/ui';
import { setTitleClass } from "../../utils/ui";

export default function RateSheet(props) {
  const rateSheetApiService = new props.rateSheetPageApiService();
  const estimateScopesDataSource = rateSheetApiService.getEstimateScopesDataSource();
  const estimateDivisionsDataSource = rateSheetApiService.getEstimateDivisionsDataSource();

  const estimateScopesDataGridName = "EstimateScopesDataGrid";
  const estimateScopesDataGridRef = useRef(null);
  const formRef = useRef(null);

  const [showEstimateScopesEditForm, setShowEstimateScopesEditForm] = useState();
  const [estimateScopeViewData, setEstimateScopeViewData] = useState();
  const [isCreate, setIsCreate] = useState(false);
  const editingEstimateScopeDto = useRef(
    {}
  );
  const editFormTitle = useRef('');


  const estimateDivisionEditorOptions = {
    dataSource: estimateDivisionsDataSource,
    searchEnabled: true,
    displayExpr: "name",
    valueExpr: "id",
  }

  const estimateScopesEditFormSaveButtonOptions = {
    text: "Save",
    stylingMode: "outlined",
    type: "default",
    onClick() {
      estimateScopeEditFormSaveButtonClick();
    }
  }

  async function estimateScopeEditFormSaveButtonClick() {
    var formInstance = formRef.current.instance;
    var validationResult = formInstance.validate();
    if (validationResult.isValid) {
        await saveEstimateScopeData()
          .then(() => {
            cleanupAndCloseEditForm();
          });
      }
    }

  function cleanupAndCloseEditForm() {
    setIsCreate(false);
    setShowEstimateScopesEditForm(false);
    setEstimateScopeViewData();
    resetEstimateScopeDto();
  }

  async function saveEstimateScopeData() {
    if (isCreate) {
      await rateSheetApiService.createEstimateScope(editingEstimateScopeDto.current);
    }
    else {
      await rateSheetApiService.updateEstimateScope(estimateScopeViewData.id, editingEstimateScopeDto.current);
    }
  }

  const estimateScopesEditFormCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      cleanupAndCloseEditForm();
    }
  }

  function estimateScopeEditFormFieldDataChanged(e) {
    if (e.dataField === "name") {
      var dtoHasName = editingEstimateScopeDto.current.hasOwnProperty("name");
      if (!dtoHasName) {
        Object.defineProperty(editingEstimateScopeDto.current, "name", { value: "", writable: true, enumerable: true });
      }
      editingEstimateScopeDto.current.name = e.value;
    }
    if (e.dataField === "estimateDivisionId") {
      var dtoHasEstimateDivisionId = editingEstimateScopeDto.current.hasOwnProperty("estimateDivisionId");
      if (!dtoHasEstimateDivisionId) {
        Object.defineProperty(editingEstimateScopeDto.current, "estimateDivisionId", { value: "", writable: true, enumerable: true });
      }
      editingEstimateScopeDto.current.estimateDivisionId = e.value;
    }
  }

  function renderEstimateScopeEditForm() {
    if (showEstimateScopesEditForm) {
      return (
        <Popup
          fullScreen={true}
          visible={showEstimateScopesEditForm}
          showCloseButton={true}
          title={editFormTitle.current}
          onHiding={() => {
            cleanupAndCloseEditForm();
          }}
        >
          <TabPanel>
            <Item 
              title="Properties"
            >
              <Form
                id="editingEstimateScopeForm"
                formData={estimateScopeViewData}
                onFieldDataChanged={estimateScopeEditFormFieldDataChanged}
                validationGroup="estimateScopeData"
                ref={formRef}
              >
                <SimpleItem dataField="name">
                  <RequiredRule
                    message="Name is required"
                  />
                </SimpleItem>
                <SimpleItem
                  dataField="estimateDivisionId"
                  editorType="dxSelectBox"
                  editorOptions={estimateDivisionEditorOptions}
                >
                  <RequiredRule
                    message="Estimate Division is required"
                  />
                  <Label 
                    text="Estimate Division"
                  />
                </SimpleItem>
              </Form>
            </Item>
            {/*<Item title="Calculations">*/}

            {/*</Item>*/}
          </TabPanel>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={estimateScopesEditFormSaveButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={estimateScopesEditFormCancelButtonOptions}
          />
        </Popup>
      )
    }
  }

  async function estimateScopesGridOnEditingStart(e) {
    setIsCreate(false);
    await loadEstimateScopeData(e.row.data.id)
      .then(() => {
        setShowEstimateScopesEditForm(true);
      });
    editFormTitle.current = "Editing: " + e.row.data.name;
  }

  async function loadEstimateScopeData(id) {
    var estimateScopeData = await rateSheetApiService.getEstimateScopeDetails(id);
    setEstimateScopeViewData(estimateScopeData);
  }

  async function createEstimateScopeButtonClick() {
    await loadEstimateScopeData(0)
      .then(() => {
        editFormTitle.current = "New Estimate Scope";
        setIsCreate(true);
        setShowEstimateScopesEditForm(true);
      })
  }

  function resetEstimateScopeDto() {
    editingEstimateScopeDto.current = {};
  }

  const createEstimateScopeButtonOptions = {
    text: "+",
    onClick() {
      createEstimateScopeButtonClick();
    }
  }

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Rate Sheet</h2>
      {renderEstimateScopeEditForm()}
      <DataGrid
        className={"dx-card wide-card"}
        dataSource={estimateScopesDataSource}
        ref={estimateScopesDataGridRef}
        id={estimateScopesDataGridName}
        height={() => calculateGridHeight(estimateScopesDataGridName)}
      >
        <Toolbar>
          <Item
            name="groupPanel"
            location="before"
          />
          <Item
            location="after"
            widget="dxButton"
            options={createEstimateScopeButtonOptions}
          />
        </Toolbar>
        <Paging defaultPageSize={50} />
        <Scrolling
          mode='virtual'
          rowRenderingMode='virtual'
        />
        <FilterRow visible={true} />
        <Grouping
          autoExpandAll={true}
          expandMode="rowClick"
        />
        <GroupPanel
          visible={true}
        />
        <Editing
          mode="popup"
          allowUpdating={false}
          allowAdding={false}
          allowDeleting={true}
        >
        </Editing>
        <Column
          name="editButton"
          type="buttons"
        >
          <Button
            icon="edit"
            onClick={(e) => estimateScopesGridOnEditingStart(e)}
          />
        </Column>
        <Column dataField="name" sortIndex={0} sortOrder="asc" />
        <Column dataField="estimateDivisionName" groupIndex={0} />
        <Column
          name="deleteButton"
          type="buttons"
        >
          <Button
            icon="trash"
            name="delete"
          />
        </Column>

      </DataGrid>
    </React.Fragment>
  );
}